import React from 'react'
import Box from '@material-ui/core/Box'
import Button from '../components/Button'
import { GatsbyImage } from 'gatsby-plugin-image'

import { INLINES, BLOCKS } from '@contentful/rich-text-types'

const assetHyperlink = {
    [INLINES.ASSET_HYPERLINK]: (node) => {
        // console.log('node --> ', node)
        const { file } = node.data.target
        const title = node.content[0].value
        return (
            <a href={`//${file.url}`} title={title}>
                {title}
            </a>
        )
    },
}

const entryHyperlink = {
    [INLINES.ENTRY_HYPERLINK]: (node) => {
        // console.log('node -->', node)

        const { name, pathPrefix } = node.data.target
        const title = node.content[0].value

        const ifLink = (name) =>
            pathPrefix ? `/${pathPrefix}/${name}` : `/${name}`

        return (
            <a href={ifLink(name)} title={title}>
                {title}
            </a>
        )
    },
}

const unordered_list = {
    [BLOCKS.UL_LIST]: (node, children) => (
        <ul
            style={{
                listStyleType: 'none',
                marginTop: '2px',
                listStyleImage:
                    'url(https://images.ctfassets.net/8tm2bdn5x93z/7bdvDJroyr2QRC4Z7K1qRV/f4a0c2257453bad967931cd6ac85f55d/dots.png)',
            }}
        >
            {children}
        </ul>
    ),
}

const paragraph = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, next) =>
            next(node.content).replace('\n', '<br/>'),
    },
}

const asset = {
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
        // console.log('node---->', node)
        const { gatsbyImageData } = node.data.target
        if (!gatsbyImageData) {
            return <h1>This is embeded</h1>
        }

        return (
            <GatsbyImage
                key={node.data.target.sys.id}
                image={gatsbyImageData}
            />
        )
    },
}

const entry = {
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        console.log('node --->', node)
        const { slug, title, link, label, __typename, pathPrefix, name } =
            node.data.target

        const ifLink = (name) =>
            pathPrefix ? `/${pathPrefix}/${name}` : `/${name}`

        switch (__typename) {
            case 'ContentfulPost':
                return (
                    <a href={`/blog/${slug}`} title={title}>
                        {title}
                    </a>
                )
            case 'ContentfulComponentButton':
                return (
                    <Button
                        to={`/${link}`}
                        color="primary"
                        variant="contained"
                        size="large"
                        disableElevation
                    >
                        {label}
                    </Button>
                )
            case 'ContentfulPage':
                return (
                    <a href={ifLink(name)} title={title}>
                        {title}
                    </a>
                )
        }
    },
}

const link = {
    [INLINES.HYPERLINK]: (node, children) => {
        // console.log('children hyperlink', children)
        return <a href={`//${node.data.uri}`}>{children}</a>
    },
}

const renderText = {
    renderText: (text) => {
        return text.split('\n').reduce((children, textSegment, index) => {
            return [...children, index > 0 && <br key={index} />, textSegment]
        }, [])
    },
}

const options = (updatedOptions) => ({
    renderNode: {
        ...updatedOptions,
        ...assetHyperlink,
        ...entryHyperlink,
        ...unordered_list,
        ...link,
        ...paragraph,
        ...entry,
        ...asset,
        ...renderText,
    },
})

export default options
